<template>
  <div>
    <div>
      <b-card>
        <p class="text-secondary my-auto mr-auto">
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche session :
          </span>
        </p>
        <div class="d-flex">
          <p class="text-secondary my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              Comité:
            </span>
            <span
              style="font-size: 25px; color: #343a40 !important"
              class="font-weight-bold"
              >{{
                selecteddata &&
                selecteddata.planning &&
                selecteddata.planning.comite
                  ? selecteddata.planning.comite.name
                  : "-"
              }}</span
            >
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              du
            </span>
            <span
              style="font-size: 25px; color: #343a40 !important"
              class="font-weight-bold"
              >{{
                moment(selecteddata.date, "DD/MM/YYYY").format("DD/MM/YYYY")
              }}</span
            >
          </p>

          <div class="row mb-3">
            <div class="col-12">
              <modal-actions
                @close="
                  $router.go(
                    $store.getters['routerHistory/fromCreation'] ? -2 : -1
                  )
                "
              />
            </div>
          </div>
        </div>
        <div class="shdow">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="
                margin-top: 13.656px !important;
                margin-bottom: 13.656px !important;
              "
            >
              Informations générales
            </p>
            <a
              href="#"
              class="mt-2"
              v-if="(isAdmin || isSuper || isGouvernance) && !editMode"
              ><i
                @click="editMode = !editMode"
                class="mdi mdi-file-document-edit-outline"
                style="font-size: 35px"
              ></i
            ></a>
            <div
              v-else-if="isAdmin || isSuper || isGouvernance"
              class="m-0 d-flex p-0"
            >
              <b-button type="submit" v-if="editMode" pill @click="updateItem"
                >Confirmer
              </b-button>
              <b-button
                style="
                  background-color: rgb(173, 181, 189);
                  border-color: rgb(173, 181, 189);
                "
                @click="editMode = !editMode"
                v-if="editMode"
                pill
                >Annuler
              </b-button>
              <a
                v-else
                href="#"
                class="text-secondary"
                style="font-size: 30px"
                @click.prevent="editMode = !editMode"
                ><i class="mdi mdi-file-document-edit"></i
              ></a>
            </div>
            <hr class="bg-secondary mb-3 mt-1 w-100" />
          </div>

          <div class="row" v-if="!editMode">
            <div class="col-md-6 col-12">
              <div class="my-3">
                <span class="font-weight-bold mr-3">Session :</span>
                <span class="badge badge-danger">{{
                  moment(selecteddata.date, "DD/MM/YYYY").format("DD/MM/YYYY")
                }}</span>
              </div>
              <div class="my-3">
                <span class="font-weight-bold mr-3">Statut :</span>
                <span>{{
                  selecteddata.status ? selecteddata.status : "-"
                }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="my-3">
                <span class="font-weight-bold mr-3">CR :</span>
                <p>
                  <span
                    v-if="selecteddata.filename ? selecteddata.filename : false"
                    style="color: black"
                    class="font-weight-bold"
                  >
                    <span>
                      <b-list-group class="mt-3">
                        <b-list-group-item>
                          <span class="">
                            <i
                              class="fa my-auto pr-1 fa-paperclip"
                              aria-hidden="true"
                            ></i>
                            <a
                              target="_blank"
                              :href="IMG_URL + selecteddata.fileUrl"
                            >
                              {{ selecteddata.filename }}</a
                            >
                          </span>
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12 col-md-6">
              <b-form-group
                label="Session :"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <date-picker
                  :value="new Date()"
                  class="col-12 p-0 font-weight-normal"
                  :placeholder="'Sessions'"
                  v-model="newSession.session"
                  required
                  format="DD/MM/YYYY"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newSession.session.$error &&
                      $v.newSession.session.$dirty,
                  }"
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Statut :"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <div class="font-weight-normal">
                  <v-select
                    :options="[
                      'Actif',
                      'Inscription close',
                      'Décision',
                      'Annulé',
                    ]"
                    placeholder="Statut"
                    v-model="newSession.statut"
                    style="padding-right: 0 !important"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newSession.statut.$error &&
                        $v.newSession.statut.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newSession.statut.$dirty"
                  >
                    {{
                      !$v.newSession.statut.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                label="CR : "
                class="font-weight-bold"
                label-cols-sm="2"
                label-align-sm="left"
              >
                <DossierFile
                  @change="filePickerChanger"
                  @click.prevent=""
                  class="font-weight-normal"
                />
                <span
                  v-if="selecteddata.filename ? selecteddata.filename : false"
                  style="color: black"
                  class="font-weight-bold"
                >
                  <b-list-group class="mt-3">
                    <b-list-group-item class="m-0"
                      ><i
                        class="fa my-auto pr-1 fa-paperclip"
                        aria-hidden="true"
                      ></i>
                      <a :href="IMG_URL + selecteddata.fileUrl" target="_blank">
                        {{ selecteddata.filename }}</a
                      >
                    </b-list-group-item>
                  </b-list-group>
                </span>
              </b-form-group>
            </div>
          </div>
        </div>
        <hr style="position: relative; top: 20px" />
        <div class="row">
          <div class="col-12">
            <b-tabs class="tab-solid tab-solid-primary mt-3">
              <b-tab title="Dossiers">
                <session-inscription-table
                  :items="selecteddata.inscriptions"
                  :session="selecteddata.name"
                  :date="selecteddata.date"
                  :loading="loadingData"
                  @confirmItem="addContributer"
                  @cancelItem="removeContributerInputs"
                  @deleteItem="deleteItem"
                  @editItem="editItem"
                  @showMember="showinvite"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
    <div v-if="showinviteList" to="modals-xyz-548">
      <ModalMembers @close="showinviteList = !showinviteList">
        <inviteinscription :items="selectedInvite" />
      </ModalMembers>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import SessionInscriptionTable from "../components/SessionInscriptionTable.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";

import { datePickerFormat } from "../../../../helpers/DatePickerFormat";
import { required } from "vuelidate/lib/validators";
import ModalMembers from "@/components/custom/ModalMembers.vue";
import Inviteinscription from "../components/Inviteinscription";
import { IMG_URL } from "@/helpers/services/api";
import DossierFile from "@/components/custom/DossierFile";

export default {
  components: {
    DossierFile,
    Inviteinscription,
    SessionInscriptionTable,
    ModalActions,
    ModalMembers,
  },
  data: () => ({
    editMode: false,
    showinviteList: false,
    selecteddata: [],
    selectedInvite: [],
    iputtest: [],
    contributers: [],
    IMG_URL,
    years: "",
    months: "",
    dossier: null,
    day: "",
    loadingData: true,
    newSession: {
      Statut: "",
      session: new Date(),
    },
    legalEntity: {
      tableItems: [
        {
          role: "Présentateur",
          user: {
            name: "J.D",
            full_name: "John Doe",
            img: "https://source.unsplash.com/random/150x150",
          },
          session: "session 1",
          folder: "Dossier 1",
          date: "02-01-2021",
          actions: ["show", "delete"],
        },
      ],
    },
  }),
  validations: {
    newSession: {
      session: {
        required,
      },

      statut: {
        required,
      },
    },
  },
  methods: {
    datePickerFormat,
    filePickerChanger(files) {
      this.dossier = files;
    },
    showinvite(data) {
      if (data.invitelist.users) {
        this.showinviteList = !this.showinviteList;
        var members = {
          name: this.selecteddata.name,
          user: data.invitelist.users,
        };
        this.selectedInvite = members;
      }
    },
    datetime(value) {
      return moment(value, "DD/MM/YYYY").format("DD/MM/YYYY");
    },
    dateti(value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY HH:MM:SS");
    },
    convertdate(value) {
      const dateString = value;
      const changedDate = dateString.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      this.years = date.getFullYear();
      this.months = date.getMonth();
      this.day = date.getDate();
      return this.years;
    },
    addContributer: function (contributer, id) {
      var sata = this.selecteddata.inscriptions.find(
        (data) => data.Modify === true
      );
      if (sata == null) {
        this.$store
          .dispatch("manage/createInscription", contributer)
          .then(() => {
            Swal.fire("L'inscription est bien créée  !", "", "success");
            this.$store.dispatch("manage/fetchSession", this.$route.params.id);
          });
      } else {
        var data = {
          ...contributer,
          id: id,
        };
        this.$store.dispatch("manage/updateInscription", data).then(() => {
          Swal.fire("L'inscription est mise à jour !", "", "success");
          this.$store.dispatch("manage/fetchSession", this.$route.params.id);
        });
      }
    },
    deleteItem(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("manage/deleteinscription", index).then(() => {
            this.$store.dispatch("manage/fetchSession", this.$route.params.id);
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
    editItem(id) {
      this.selecteddata.inscriptions = this.selecteddata.inscriptions.map(
        (contributer) =>
          contributer.id === id
            ? { ...contributer, editMode: true, Modify: true }
            : contributer
      );
    },
    addContributerInputs() {
      var data = this.selecteddata.inscriptions.some(
        (contribute) => contribute.editMode
      );
      if (data == false) {
        this.selecteddata.inscriptions.unshift({
          editMode: true,
        });
      }
    },
    removeContributerInputs() {
      var data = this.selecteddata.inscriptions.find(
        (contributer) => contributer.Modify == true
      );

      if (data == null) {
        this.selecteddata.inscriptions.splice(0, 1);
      } else {
        this.$store.dispatch("manage/fetchSession", this.$route.params.id);
      }
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("manage/updateSession", {
            id: this.SESSION.id,
            date: moment(this.newSession.session, "DD/MM/YYYY").format(
              "DD/MM/YYYY HH:MM:SS"
            ),
            status: this.newSession.statut,
          })
          .then(() => {
            Swal.fire("La session est mise à jour !", "", "success");

            this.$store.dispatch("manage/fetchSession", this.$route.params.id);
            if (this.dossier.length) {
              this.dossier.map((data) => {
                this.$store
                  .dispatch("manage/createDossierFile", {
                    id: this.$route.params.id,
                    file: data,
                  })
                  .then(() => {
                    this.$store.dispatch(
                      "manage/fetchSession",
                      this.$route.params.id
                    );
                  });
              });
            } // get current task
          });

        this.editMode = !this.editMode;
      }
    },
  },
  mounted() {},
  watch: {
    SESSION() {
      this.selecteddata = this.SESSION;
      this.selecteddata.inscriptions = this.selecteddata.inscriptionsComite.map(
        (data) => ({
          ...data,
          dossierx: data.dossier,
          id: data.id,
          livrable: data.dossier
            ? {
                id: data.dossier.id,
                filename: data.dossier.filename,
                fileUrl: data.dossier.fileUrl,
              }
            : "-",
          type: data.dossier
            ? data.dossier.initiative
              ? "Initiative"
              : data.dossier.feuileroute
              ? "FdR"
              : data.dossier.cadre
              ? "Cadre"
              : data.dossier.application
              ? "Application"
              : data.dossier.composant
              ? "Composant Technique"
              : "Dossier libre"
            : "-",
          temps: data.temps,
          decision: data.decision ? data.decision : "-",
          dossier: data.dossier
            ? data.dossier.initiative
              ? data.dossier.initiative.libelle
              : data.dossier.feuileroute
              ? data.dossier.feuileroute.name
              : data.dossier.cadre
              ? data.dossier.cadre.name
              : data.dossier.application
              ? data.dossier.application.name
              : data.dossier.composant
              ? data.dossier.composant.name
              : data.dossier.name
            : "-",
        })
      );

      this.newSession = {
        ...this.SESSION,
        statut: this.SESSION.status ? this.SESSION.status : "",
        session: this.SESSION.date ? datePickerFormat(this.SESSION.date) : "",
        date: this.SESSION.date ? datePickerFormat(this.SESSION.date) : "",
      };
    },
  },
  created() {
    this.$store
      .dispatch("manage/fetchSession", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("planning/fetchAllPlans");
  },

  computed: {
    ...mapGetters("manage", ["SESSION"]),
    ...mapGetters("planning", ["PLANNINGS"]),
    ...mapGetters(["isAdmin", "isSuper", "isGouvernance"]),
    planning() {
      var data = this.PLANNINGS.map((plan) => ({
        value: `/api/gouvernance/plannings/${plan.id}`,
        text: plan.name,
      }));

      return data;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
  },
};
</script>

<style></style>
